
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.faqs {
  padding: 1rem;
}
.faqs h1 {
  text-decoration: underline;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.faqs h2 {
  font-size: 1.3rem;
  font-weight: 600;
}
.faqs p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: static;
  bottom: 0;
  height: 2rem;
  width: 100vw;
  background: white;
  color: $footer-text;
  p {
    padding-right: $padding1x;
    font-size: $font1x;
  }
}
