
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

hr {
  border: 0;
  border-bottom: 1px dotted $fill-grey;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}

a.link {
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 0px;
    height: 2px;
    margin: 5px 0 0;
    transition: all 100ms ease-out;
    transition-duration: 0.75s;
    background-color: darken(#ea5393, 5%);
    left: 0;
  }

  &:hover {

    &::before,
    &::after {
      width: 75px;
    }
  }
}
